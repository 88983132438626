<template>
  <div>
    <!-- 基本信息 -->
    <div class="basicInfo">
      <div class="infoTit">
        <div>基本信息</div>
        <!-- <div @click="showDrawer"><a>编辑</a></div> -->
      </div>
      <div class="basicInfo_edit">
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="所属公司">
            <a-select
              v-model="form.qbcId"
              placeholder="请输入"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in GetCompanyList"
                :key="item.bc_id"
                :value="item.bc_id"
              >
                {{ item.bc_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="报价名称">
            <a-input
              v-model="form.qname"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="报价类型">
            <a-select
              v-model="form.qtype"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in QuotationType"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <br />
          <a-form-model-item label="套餐名称">
            <!-- <a-input v-model="form.ptName" placeholder="请输入" /> -->

            <a-select
              v-model="form.qptId"
              style="width: 150px"
              :disabled="viewShow"
            >
              <a-select-option
                v-for="item in PackageTemplateList"
                :key="item.ptId"
                :value="item.ptId"
              >
                {{ item.ptName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="套餐编号">
            <!-- <a-input v-model="form.qNumber" placeholder="请输入" disabled /> -->
            <div class="width240">{{ form.qNumber }}</div>
          </a-form-model-item>
          <a-form-model-item label="套餐类别">
            <!-- <a-input v-model="form.qPtPtId" placeholder="请输入" disabled /> -->
            <div class="width240">{{ form.qPtPtId }}</div>
          </a-form-model-item>
          <br />
          <a-form-model-item label="施工量(平方米)">
            <a-input
              v-model="form.qarea"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="单价">
            <!-- <a-input v-model="form.qmoney" placeholder="请输入" disabled /> -->
            <div class="width240">￥{{ form.qPackageMoney }}</div>
            <!-- :disabled="viewShow" -->
          </a-form-model-item>

          <a-form-model-item label="套餐价(元)">
            <!-- <a-input v-model="form.qtotal" placeholder="请输入" disabled /> -->
            <div class="colorChange">￥{{ form.qpackage }}</div>
          </a-form-model-item>
          <br />
          <!-- 卧室个数 -->
          <a-form-model-item label="房型：" class="width140">
            <a-input
              v-model="form.qroom"
              :disabled="viewShow"
              class="width50"
            />
            室
          </a-form-model-item>
          <!-- 客厅个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qhall"
              :disabled="viewShow"
              class="width50"
            />
            厅
          </a-form-model-item>
          <!-- 厨房个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qkitchen"
              :disabled="viewShow"
              class="width50"
            />
            厨
          </a-form-model-item>
          <!-- 卫生间个数 -->
          <a-form-model-item label="" class="width80">
            <a-input
              v-model="form.qtoilet"
              :disabled="viewShow"
              class="width50"
            />
            卫
          </a-form-model-item>
          <!-- 阳台个数 -->
          <a-form-model-item label="" class="width140">
            <a-input
              v-model="form.qbalcony"
              :disabled="viewShow"
              class="width50"
            />
            阳台
          </a-form-model-item>
          <a-form-model-item label="客户姓名">
            <a-input
              v-model="form.qcusName"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <a-form-model-item label="联系电话">
            <a-input
              v-model="form.qcusTel"
              placeholder="请输入"
              :disabled="viewShow"
            />
          </a-form-model-item>
          <br />
          <div class="textareaWidth">
            <a-form-model-item label="工地地址">
              <a-textarea
                v-model="form.qaddress"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item>
            <a-form-model-item label="说明">
              <a-textarea
                v-model="form.qdesc"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item>
            <br />
            <a-form-model-item label="套餐概要">
              <a-textarea
                v-model="form.qSummary"
                placeholder="请输入"
                :auto-size="{ minRows: 1, maxRows: 3 }"
                :disabled="viewShow"
              />
            </a-form-model-item>
          </div>
          <!-- <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
            <a-button type="primary"> 保存 </a-button>
            <a-button style="margin-left: 10px" @click="onClose">
              取消
            </a-button>
          </a-form-model-item> -->
        </a-form-model>
      </div>
    </div>
    <!-- 详细信息 -->
    <div>
      <div class="infoTit">详细信息</div>
      <div class="tableHeight">
        <!--       :scroll="{ y: 550 }"
          tableLayout="fixed" -->
        <div class="addBtn" v-if="!viewShow">
          <a-button
            type="primary"
            @click="showDrawer('QuotationEditShow', '新增', 'add', '')"
            >新增</a-button
          >
        </div>
        <!--      rowKey="qdPId"   WorkProceList.length > 0 &&ProjectList -->
        <a-table
          ref="table"
          size="small"
          :rowKey="
            (record, index) => {
              return record.wpId
                ? record.wpId
                : record.qdWpId + '-' + record.qdPId;
            }
          "
          :columns="columns"
          :data-source="tabledata"
          :scroll="{ x: 1300, y: 400 }"
          :defaultExpandAllRows="true"
          v-if="tableShow"
          :pagination="false"
        >
          <!-- :showPagination="false" -->
          <span slot="wpId" slot-scope="text">
            <div>{{ WorkProceResult(text) }}</div>
          </span>
          <!-- <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            {{ record.children }}
          </p> -->
          <span slot="qdPId" slot-scope="text">
            <div>
              {{ ProjectListResult(text) ? ProjectListResult(text) : text }}
            </div>
          </span>
          <!-- <span slot="mainMaterials" slot-scope="text, record">
            {{ text ? text : "" }}
          </span> -->
          <span slot="qdBcId" slot-scope="text">
            <div>{{ text ? CompanyListResult(text) : "" }}</div>
          </span>
          <!-- 操作 -->
          <span slot="action" slot-scope="text, record" class="action">
            <a
              class="editBtn"
              type="primary"
              @click="showDrawer('QuotationEditShow', '新增', 'add', record)"
              v-show="!viewShow"
              >新增</a
            >
            <a
              v-if="record.qdPId && !viewShow"
              class="editBtn"
              @click="showDrawer('QuotationEditShow', '修改', 'edit', record)"
              >修改</a
            >
            <!--  @click="showDrawer('BuildPackageEdit', '修改', 'edit', record)" -->
            <a
              class="editBtn"
              v-if="record.qdPId"
              @click="showDrawer('QuotationViewShow', '详情', 'view', record)"
              >详情</a
            >
            <a-popconfirm
              title="确定删除此条报价信息吗?"
              @confirm="() => onDelete(record)"
            >
              <a class="delName" v-if="record.qdPId && !viewShow">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
      <div class="btnsDisplayflex">
        <div>额外收费项</div>
        <div v-if="viewShow" class="btnsDisplayflex_inner">
          <div v-for="(item, index) in this.NewExtraCharge" :key="index">
            {{ ExtraChargeListResult(item.qdPId) }}
          </div>
        </div>
        <a-checkbox-group
          @change="changeradioBtn"
          v-model="NewExtraChargeValue"
          v-if="!viewShow"
        >
          <a-row>
            <a-col :span="3" v-for="el in ExtraChargeList" :key="el.ecId">
              <a-checkbox :value="el.ecId"> {{ el.ecName }} </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>
      <a-divider />
      <div class="bottom">
        <div class="displayFlex">
          <div>总金额</div>
          <div>￥{{ Math.round(form.qtotal * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>套餐价</div>
          <div>￥{{ Math.round(form.qpackage * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>优惠</div>
          <div>￥{{ Math.round(form.qcheap * 100) / 100 }}</div>
        </div>
        <a-divider />
        <div class="displayFlex">
          <div>调整费用</div>
          <div>￥{{ Math.round(form.qchange * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>减少项</div>
          <div>￥{{ Math.round(form.qreduce * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>增加项</div>
          <div>￥{{ Math.round(form.qincrease * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>主材总金额</div>
          <div>￥{{ Math.round(form.qmmoney * 100) / 100 }}</div>
        </div>
        <div class="displayFlex">
          <div>
            实际价格
            <a-tooltip placement="right">
              <template slot="title">
                <div>实际价格=套餐价-减少项+增加项+调整费用+主材金额</div>
              </template>
              <a-icon type="question-circle" theme="filled" />
            </a-tooltip>
          </div>
          <div>￥{{ form.qmoney.toFixed(2) }}</div>
        </div>
      </div>

      <div class="padding_bottom">
        <!-- <a-button type="primary" @click="submitClick()">提交</a-button>
        <a-button @click="cancellClick()" style="margin-left: 20px"
          >取消</a-button
        > -->
        <div v-if="viewShow">
          <a-button type="primary" @click="showEdit()">编辑</a-button>
          <a-button @click="cancellClick()" style="margin-left: 20px"
            >返回</a-button
          >
        </div>
        <div v-if="!viewShow">
          <a-button type="primary" @click="submitClick()">提交</a-button>

          <a-button @click="cancellClick()" style="margin-left: 20px"
            >取消</a-button
          >
        </div>
      </div>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <QuotationEdit
        @onClose="onClose"
        :record="JSON.stringify(record)"
        :type="recordType"
        ref="QuotationEdit"
        v-if="QuotationEditShow"
        @tabledataChange="tabledataChange"
      />
      <QuotationView
        @onClose="onClose"
        :record="JSON.stringify(record)"
        :type="recordType"
        ref="QuotationView"
        v-if="QuotationViewShow"
      />
      <!-- @tabledataChange="($event) => tabledataChange()" -->
    </a-drawer>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { STable, SSearch, ColumnsSet } from "@/components";
import { AddQuotation, UpdateQuotation } from "@/api/apiJF/quotation";
import { GetPackageTemplateList, GetPackageDetail } from "@/api/apiJF/package";
import {
  GetWorkProceList,
  GetPackageTypeList,
  DeleteWorkProce,
  GetProjectList,
  GetExtraChargeList,
  GetProjectMaterialList,
} from "@/api/apiJF/template";
import { GetQuotationDetailList } from "@/api/apiJF/quotation";
import QuotationEdit from "./QuotationEdit.vue";
import QuotationView from "./QuotationView.vue";

export default {
  name: "BuildQuotation",
  components: { QuotationEdit, STable, QuotationView },
  data() {
    return {
      viewShow: true, //是否显示详情
      queryParam: {
        qdQId: "", //报价id
        qdBcIds: "18",
        qdWpId: "", //施工工序id
        qdPId: "", //施工项id
        qdType: "", //施工类型，1基础工程2额外收费
      },
      DrawerTitle: "",
      GetCompanyList: {},
      CompanyListid: [],
      CompanyList: {},
      PackageTypeListId: {}, //套餐类别
      PackageTypeList: [],
      tablePriceList: [],
      // columns,
      columns: [
        {
          title: "施工工序",
          dataIndex: "wpId",
          key: "wpId",
          width: "100px",
          // align: "left",
          scopedSlots: { customRender: "wpId" },
        },
        {
          title: "施工项",
          dataIndex: "qdPId",
          key: "qdPId",
          // width: "100px",
          // align: "left",
          scopedSlots: { customRender: "qdPId" },
        },
        {
          title: "公司名称",
          dataIndex: "qdBcId",
          key: "qdBcId",
          width: "100px",
          // align: "left",
          scopedSlots: { customRender: "qdBcId" },
        },
        {
          title: "施工量",
          dataIndex: "qdQuantity",
          key: "qdQuantity",
          width: "90px",
          align: "center",
        },
        {
          title: "核算单位单价(元)",
          dataIndex: "qdPrice",
          key: "qdPrice",
          width: "110px",
          align: "center",
        },
        {
          title: "总金额",
          dataIndex: "qdMoney",
          // customRender: (text, record) => {
          //   let value = record.qdQuantity
          //     ? record.qdQuantity * record.qdPrice
          //     : "";
          //   return text ? text : value;
          // },
          width: "90px",
          align: "center",
        },
        {
          title: "主材",
          dataIndex: "mainMaterials",
          // key: "mainMaterials",
          // scopedSlots: { customRender: "mainMaterials" },
          customRender: (text, record) => {
            if (text) {
              // console.log("表格里的主材", text.length);
              // let maMId = JSON.parse(text)[0].maMId;
              // console.log(maMId);
              let idList = [];

              // if (text.length > 50) {
              if (typeof text == "string") {
                JSON.parse(text).forEach((el) => {
                  idList.push(this.ProjectMaterialResult(el.maMId));
                  idList.push(<br />);
                });
              } else {
                if (typeof text == "object") {
                  text.forEach((el) => {
                    idList.push(this.ProjectMaterialResult(el.maMId));
                    idList.push(<br />);
                  });
                }
              }
              return idList;
            } else {
              return "";
            }
          },
          width: "180px",
          align: "center",
        },
        {
          title: "主材用量",
          dataIndex: "pmCreateTime",
          customRender: (text, record) => {
            if (record.mainMaterials) {
              // console.log(
              //   "record.mainMaterials",
              //   record.mainMaterials.length,
              //   record.mainMaterials
              // );
              let valueList = [];
              // if (record.mainMaterials.length > 50) {
              if (typeof record.mainMaterials == "string") {
                JSON.parse(record.mainMaterials).forEach((el) => {
                  let value = "";
                  this.ProjectMaterialListMain.forEach((item) => {
                    if (item.pmMId == el.maMId) {
                      value = el.maQuantity + item.tmaterial.mUnit;
                    }
                  });
                  valueList.push(value);
                  valueList.push(<br />);
                });
              } else {
                // if (record.mainMaterials.length > 2) {
                if (typeof record.mainMaterials == "object") {
                  record.mainMaterials.forEach((el) => {
                    let value = "";
                    this.ProjectMaterialListMain.forEach((item) => {
                      if (item.pmMId == el.maMId) {
                        value = el.maQuantity + item.tmaterial.mUnit;
                      }
                    });
                    valueList.push(value);
                    valueList.push(<br />);
                  });
                }
              }
              return valueList;
            }
          },
          width: "90px",
          align: "center",
        },
        {
          title: "主材费用(元)",
          // qdOrder
          // dataIndex: "qdPrice",
          // key: "qdPrice",
          dataIndex: "qdOrder",
          customRender: (text, record) => {
            if (record.mainMaterials) {
              let valueList = [];
              // console.log(
              //   "record.mainMaterials.length",
              //   record.mainMaterials.length
              // );

              // if (record.mainMaterials.length > 20) {
              if (typeof record.mainMaterials == "string") {
                // console.log(
                //   "  record.mainMaterials",
                //   JSON.parse(record.mainMaterials)
                // );
                JSON.parse(record.mainMaterials).forEach((el) => {
                  // record.mainMaterials.forEach((el) => {
                  let value = "";
                  this.ProjectMaterialListMain.forEach((item) => {
                    if (item.pmMId == el.maMId) {
                      // console.log(item, el);

                      value = el.maQuantity * item.tmaterial.mSalesPrice;
                      // value = value.toFixed(2);
                      value = Math.round(value * 100) / 100;
                      // console.log("主材value", value);
                    }
                  });
                  valueList.push(value);
                  valueList.push(<br />);
                });
              } else {
                // if (record.mainMaterials.length > 2) {
                if (typeof record.mainMaterials == "object") {
                  record.mainMaterials.forEach((el) => {
                    let value = "";
                    this.ProjectMaterialListMain.forEach((item) => {
                      if (item.pmMId == el.maMId) {
                        value = el.maQuantity * item.tmaterial.mSalesPrice * 1;
                        value = Math.round(value * 100) / 100;
                        // console.log("主材value", value);
                      }
                    });
                    valueList.push(value);
                    valueList.push(<br />);
                  });
                }
              }
              return valueList;
            }
          },
          width: "90px",
          align: "center",
        },

        {
          title: "备注",
          dataIndex: "qdDesc",
          key: "qdDesc",
          width: "150px",
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: "200px",
          align: "center",
          fixed: "right",
        },
      ],
      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      ExtraChargeList: [], //额外收费项
      ProjectMaterialListMain: [], //主材物料信息
      ProjectMaterialListMainLis: [], //主材物料信息id
      visibleDrawer: false,
      form: {
        qbcId: "", //分公司id
        qname: "", //报价名称
        qNumber: "", //编号
        qptId: "", //套餐Id
        qPtPtId: "", //套餐类别ID
        qName: "", //套餐名称
        ptUnit: "", //报价单位
        qdesc: "", //备注说明
        qPackageMoney: 0, //套餐单价
        qtype: "", //报价类型
        qcusName: "", //客户姓名
        qcusTel: "", //客户电话
        qdesName: "", //设计师姓名
        qpartyB: "", //乙方
        qarea: 0, //房屋面积
        qroom: "", //卧室个数
        qhall: "", //客厅个数
        qkitchen: "", //厨房个数
        qtoilet: "", //卫生间个数
        qbalcony: "", //阳台个数
        qSummary: "", //套餐概要
        qtotal: 0, //总金额=表格总金额列之和+额外收费项金额
        qpackage: 0, //套餐价
        qcheap: 0, //优惠
        qchange: 0, //调整费用
        qreduce: 0, //减少项
        qincrease: 0, //增加项
        qmoney: 0, //实际价格
        qmmoney: 0, //主材总金额
        qaddress: "", //工地地址
      },
      tabledata: [],
      record: {
        qdBcId: "",
      },
      recordType: "",
      NewExtraCharge: [],
      NewExtraChargeValue: [],
      ExtraChargeListId: {},
      isShowcancellBtn: true,
      QuotationType: [
        { value: 1, label: "量房前报价" },
        { value: 2, label: "量房后报价" },
      ],
      PackageTemplateList: [], //套餐模版
      tableShow: false,
      QuotationEditShow: false,
      QuotationViewShow: false,
      PackageTypeListPrice: [], //套餐模版id和价格
      ExtraChargePrice: 0, //额外收费项总金额
      tablePrice: 0, //表格总金额之和
      oldTableData: [], //原始表格数据，编辑时，是query里的project，add时，是套餐里的赋值的表格数据
      addTableData: [],
      qincrease_table: 0, //表格里的增加项
    };
  },
  created() {
    this.GetCompaniesId();
    // console.log("this.$route.query", this.$route.query);
    // 路由传参，循环赋值
    let query = JSON.parse(this.$route.query.record);
    // console.log("query.record", query);
    if (query) {
      if (Object.keys(query).length != 0) {
        for (let el in query) {
          this.form[el] = query[el];
        }
        this.queryParam.qdQId = query.qid;
        this.queryParam.qdBcIds = query.qbcId;
        this.record.qdBcId = query.qdBcId;
        this.record.qdQId = query.qid;
        // 如果是编辑 则查询详细信息
        // 查询套餐详情
        this.GetPackageDetail(this.form.qptId, this.form.qbcId);
        // console.log("this.$route.query.type", this.$route.query.type);
        // 获取额外收费项
        this.GetExtraChargeList(this.form.qbcId);
      }
    }
    if (this.$route.query.type == "edit") {
      this.loadData();
      this.$route.meta.title = "报价详情";
    } else {
      this.tabledata = [];
      this.viewShow = false;
      this.$route.meta.title = "新增报价";
    }
    // console.log("  this.form", this.form);
    setTimeout(() => {
      this.tableShow = true;
    }, 300);
  },
  mounted() {
    console.log("获取角色信息", this.$store.state);
  },
  computed: {},
  watch: {
    "form.qbcId": {
      handler(newValue) {
        // console.log("form.qbcId", this.form.qbcId);
        // this.tabledata = [];
        if (newValue) {
          // 获取套餐类别
          this.GetPackageTypeList(newValue);
          this.record.qdBcId = newValue;
          // 获取额外收费项
          this.GetExtraChargeList(newValue);
          // 获取施工工序
          this.GetWorkProceList(newValue);
          // 获取施工项
          this.GetProjectList(newValue);
          // 获取套餐模版
          this.GetPackageTemplateList(newValue);
          //查询主材物料信息
          this.GetProjectMaterialList(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    "form.qptId": {
      handler(newValue) {
        this.PackageTemplateList.forEach((el) => {
          if (el.ptId == newValue) {
            // console.log("选中的套餐", el);
            this.form.qPtPtId = this.PackageTypeListResult(el.ptPtId); //套餐类别
            this.form.qNumber = el.ptNumber; //套餐编号
            this.form.qName = el.ptName;
            this.form.qPackageMoney = el.ptPrice;
            // 查询套餐详情
            this.GetPackageDetail(newValue, this.form.qbcId);
          }
        });
      },
      // immediate: true,
      // deep: true,
    },
    "form.qarea": {
      handler(newValue) {
        // console.log("form.qarea", newValue);
        // 计算套餐价
        this.form.qpackage = this.form.qPackageMoney * this.form.qarea;
        // 计算施工项的施工量
        if (this.$route.query.type == "add") {
          let price = 0;
          let qdCheapMoney = 0;
          let tabledata = [...this.tabledata];
          tabledata.forEach((el) => {
            if (el.children) {
              el.children.forEach((item) => {
                // console.log("form.qarea", item, item.qdRatio);
                item.qdQuantity = this.form.qarea * item.qdRatio;
                item.qdMoney = item.qdQuantity * item.qdPrice; //计算施工项金额
                // let money = item.qdQuantity * item.qdPrice;
                // price = price * 1 + money * 1;
                // 计算优惠金额
                item.qdCheapMoney = item.qdQuantity * item.qdCheapPrice;
                qdCheapMoney = qdCheapMoney * 1 + item.qdCheapMoney;
                // console.log(
                //   "施工量面积改变---优惠金额",
                //   item.qdQuantity,
                //   item.qdCheapPrice,
                //   item.qdCheapMoney
                // );
                // console.log("this.form.qarea", this.form.qarea, item.qdRatio);
              });
            }
          });
          this.form.qdCheapMoney = qdCheapMoney;
          this.tabledata = tabledata;
          this.form.qtotal = this.tablePrice * 1 + this.ExtraChargePrice * 1;
        }
        // 原数组 套餐里包含的施工项的施工量跟随施工面积改变
        let oldTableData = [...this.oldTableData];
        oldTableData.forEach((el) => {
          el.children.forEach((item) => {
            item.pdQuantity = this.form.qarea * item.pdRatio;
          });
        });
        this.oldTableData = oldTableData;
      },
      immediate: true,
    },
    // 监听表格数据， 获取表格内每条施工项金额   计算总金额
    tabledata: {
      handler(newValue) {
        // console.log("表格", newValue);
        let price = 0;
        let AdjPrice = 0;
        let qcheap = 0; //优惠金额
        let qmmoney = 0; //主材金额
        newValue.forEach((el) => {
          el.children.forEach((item) => {
            qcheap = qcheap * 1 + item.qdCheapMoney * 1; //计算优惠金额
            let money = item.qdQuantity * item.qdPrice;
            price = price * 1 + money * 1;
            // console.log("主材金额", item);
            qmmoney = qmmoney * 1 + item.qdMPrice * 1;
            // 调整费用
            if (item.tmaterialAdjustments) {
              item.tmaterialAdjustments.forEach((Adjus) => {
                AdjPrice = AdjPrice * 1 + Adjus.maAdjPrice * 1;
              });
              // console.log("调整费用", AdjPrice, item.tmaterialAdjustments);
            }
            // if (item.mainMaterials.length > 100) {
            //   let mainMaterials = JSON.parse(item.mainMaterials);
            //   mainMaterials.forEach((el) => {});
            // }
          });
        });
        // console.log("总金额相加", price);
        this.form.qchange = AdjPrice; //调整费用
        this.tablePrice = price;
        this.form.qtotal = this.tablePrice * 1 + this.ExtraChargePrice * 1;
        this.form.qcheap = qcheap.toFixed(2); //优惠总金额
        this.form.qmmoney = qmmoney; //主材金额
        // console.log("表格监听到新增或删除", this.tabledata);
      },
      deep: true,
      immediate: true,
    },
    // 监听额外收费项， 获取额外收费项金额   计算总金额
    NewExtraCharge: {
      handler(newValue) {
        setTimeout(() => {
          // console.log("额外收费项", newValue);
          // console.log("总额外收费项", this.ExtraChargeList);
          let price = 0;
          newValue.forEach((el) => {
            this.ExtraChargeList.forEach((item) => {
              // console.log("item", el.qdPId, item.ecId);
              if (el.qdPId == item.ecId) {
                // console.log("额外收费项金额", item.ecCost);
                price = price * 1 + item.ecCost * 1;
              }
            });
          });
          this.ExtraChargePrice = price;
          this.form.qtotal = this.tablePrice * 1 + this.ExtraChargePrice * 1;
          // console.log("额外收费项总金额相加", this.ExtraChargePrice);
          // console.log("this.form.qtotal", this.form.qtotal, this.tablePrice);
          this.form.qincrease =
            this.qincrease_table * 1 + this.ExtraChargePrice * 1;
          // 实际价格=套餐价-减少项+增加项+调整费用

          this.form.qmoney =
            this.form.qpackage -
            this.form.qreduce +
            this.form.qincrease * 1 +
            this.form.qchange * 1 +
            this.form.qmmoney * 1;
        }, 100);
      },
      // immediate: true,
    },
    //监听总金额，计算优惠金额
    "form.qtotal": {
      handler(newValue) {
        // this.form.qcheap = this.form.qtotal - this.form.qpackage;
      },
    },
    // 监听表格里的增加项
    qincrease_table(newValue) {
      this.form.qincrease = newValue * 1 + this.ExtraChargePrice * 1;
    },
    //监听套餐价，计算实际价格
    "form.qpackage": {
      handler(newValue) {
        // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
        this.form.qmoney =
          this.form.qpackage -
          this.form.qreduce +
          this.form.qincrease * 1 +
          this.form.qchange * 1 +
          this.form.qmmoney * 1;
        console.log("套餐价", newValue, this.form.qmoney);
      },
    },
    //监听减少项，计算实际价格
    "form.qreduce": {
      handler(newValue) {
        // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
        this.form.qmoney =
          this.form.qpackage -
          this.form.qreduce +
          this.form.qincrease * 1 +
          this.form.qchange * 1 +
          this.form.qmmoney * 1;
        console.log("减少项", newValue, this.form.qmoney);
      },
    },
    //监听增加项，计算实际价格
    "form.qincrease": {
      handler(newValue) {
        // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
        this.form.qmoney =
          this.form.qpackage -
          this.form.qreduce +
          this.form.qincrease * 1 +
          this.form.qchange * 1 +
          this.form.qmmoney * 1;
        console.log("增加项", newValue, this.form.qmoney);
      },
    },
    //监听调整费用，计算实际价格
    "form.qchange": {
      handler(newValue) {
        // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
        this.form.qmoney =
          this.form.qpackage -
          this.form.qreduce +
          this.form.qincrease * 1 +
          this.form.qchange * 1 +
          this.form.qmmoney * 1;
        console.log("调整费用", newValue, this.form.qmoney);
      },
    },
    //监听主材总金额，计算实际价格
    "form.qmmoney": {
      handler(newValue) {
        // 实际价格=套餐价-减少项+增加项+调整费用+主材金额
        this.form.qmoney =
          this.form.qpackage -
          this.form.qreduce +
          this.form.qincrease * 1 +
          this.form.qchange * 1 +
          this.form.qmmoney * 1;
        console.log("主材总金额", newValue, this.form.qmoney);
      },
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.qdBcIds = this.CompanyListid.toString();
        })
        .then(() => {
          // if (this.$route.query.type == "edit") {
          //   this.loadData();
          // }
        });
    },
    // 根据分公司id查询分公司名称
    CompanyListResult(a) {
      return this.CompanyList[a];
    },
    loadData() {
      this.tabledata = JSON.parse(this.$route.query.record).project;
      // console.log(" this.tabledata11111", this.tabledata);
      this.tabledata.forEach((el) => {
        el.children.forEach((item) => {
          item.childrenWpId = el.wpId;
          item.mainMaterials = JSON.stringify(item.mainMaterials);
        });
      });
      let extra = JSON.parse(this.$route.query.record).extra;
      // console.log("extra", extra);
      let NewExtraChargeValue = [];
      let NewExtraCharge = [];
      extra.forEach((item) => {
        NewExtraChargeValue.push(item.qdPId);
        NewExtraCharge.push({
          qdBcId: this.form.qbcId,
          qdType: "2",
          qdPId: item.qdPId,
          qdQId: this.form.qid,
        });
      });
      this.NewExtraChargeValue = NewExtraChargeValue;
      this.NewExtraCharge = NewExtraCharge;
      // console.log(
      //   "  this.NewExtraChargeValue",
      //   this.NewExtraChargeValue,
      //   this.NewExtraCharge
      // );
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params)
        .then((res) => {
          // console.log("施工工序", res.data);
          res.data.forEach((el) => {
            this.WorkProceList[el.wpId] = el.wpName;
          });
          // 如果是新增 则展示所有施工工序
          if (this.$route.query.type == "add") {
            this.tabledata = [];
            setTimeout(() => {
              res.data.forEach((el) => {
                this.tabledata.push({
                  wpId: el.wpId,
                  children: [],
                });
              });
            });
          }
          // console.log("施工工序", res.data, this.WorkProceList.length);
          // console.log(" 施工工序 --this.tabledata", this.tabledata);
        })
        .then(() => {});
    },
    // 根据施工工序id查询施工工序名称
    WorkProceResult(a) {
      return this.WorkProceList[a];
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        res.data.forEach((el) => {
          this.ProjectList[el.pid] = el.pname;
          el.children.forEach((item) => {
            this.ProjectList[item.pid] = item.pname;
          });
        });
        // console.log("施工项", res.data);
      });
    },
    // 根据施工项id查询施工工序名称
    ProjectListResult(a) {
      return this.ProjectList[a];
    },
    // 获取套餐类别
    GetPackageTypeList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageTypeList(params).then((res) => {
        this.PackageTypeList = res.data;
        res.data.forEach((el) => {
          this.PackageTypeListId[el.ptId] = el.ptName;
          el.children.forEach((item) => {
            this.PackageTypeListId[item.ptId] = item.ptName;
          });
        });
      });
    },
    // 根据套餐类别id查询套餐名称
    PackageTypeListResult(a) {
      return this.PackageTypeListId[a];
    },
    // 获取套餐模版
    GetPackageTemplateList(ptBcIds) {
      let queryParam = {
        ptBcIds: ptBcIds ? ptBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ptBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageTemplateList(params).then((res) => {
        // console.log("套餐模版", res.data);
        this.PackageTemplateList = res.data;
        // 套餐模版id和价格
        res.data.forEach((el) => {
          this.PackageTypeListPrice[el.ptId] = el.ptPrice;
          if (el.children) {
            el.children.forEach((item) => {
              this.PackageTypeListPrice[item.ptId] = item.ptPrice;
            });
          }
        });

        this.PackageTemplateList.forEach((el) => {
          if (el.ptId == this.form.qptId) {
            this.form.qPtPtId = this.PackageTypeListResult(el.ptPtId); //套餐类别
            this.form.qNumber = el.ptNumber; //套餐编号
            this.form.qName = el.ptName;
            this.form.qPackageMoney = el.ptPrice;
            this.form.qpackage = el.ptPrice * this.form.qarea;
          } else {
          }
        });
      });
    },
    // 根据套餐模版id获取套餐价格
    PackageTypeListPriceResult(a) {
      return this.PackageTypeListPrice[a];
    },
    // 获取物料信息---主材
    GetProjectMaterialList(pmBcIds, pmPId) {
      let queryParam = {
        // pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "1",
      };
      let orderParam = ["pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("物料信息", res.data);
        res.data.forEach((el) => {
          this.ProjectMaterialListMainLis[el.pmMId] = el.tmaterial.mName;
        });
        this.ProjectMaterialListMain = res.data;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 根据物料id查询name
    ProjectMaterialResult(a) {
      return this.ProjectMaterialListMainLis[a];
    },
    // 查询套餐详情
    GetPackageDetail(pdPtId, pdBcId) {
      let queryParam = {
        pdPtId: pdPtId,
        pdBcId: pdBcId,
      };
      let orderParam = ["pdPtId", "pdBcId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageDetail(params).then((res) => {
        // console.log("套餐详情", res.data);
        this.oldTableData = res.data.project;

        // console.log(" this.oldTableData ", this.oldTableData);
        // 新增时选择套餐，默认添加套餐所包含的施工项，如果是编辑，则不添加
        if (this.$route.query.type == "add") {
          // 表格数据
          let project = res.data.project;
          let price = 0;
          let newtabledata = [];
          project.forEach((el, index) => {
            newtabledata.push({
              wpId: el.wpId,
            });
          });
          setTimeout(() => {
            project.forEach((el) => {
              newtabledata.forEach((item) => {
                if (item.wpId == el.wpId) {
                  let children = [];
                  if (el.children) {
                    el.children.forEach((child) => {
                      children.push({
                        qdPId: child.pdPId,
                        qdRatio: child.pdRatio,
                        qdPrice: child.pdPrice,
                        qdWpId: el.wpId,
                        qdQuantity: this.form.qarea * child.pdRatio,
                        childrenWpId: el.wpId,
                        // 优惠单价
                        qdCheapPrice: child.pdCheapPrice,
                        // 优惠金额
                        qdCheapMoney: 0,
                        //  施工项金额
                        qdMoney:
                          child.pdPrice * this.form.qarea * child.pdRatio,
                        //  主材金额
                        qdMPrice: 0,
                      });
                    });
                  }
                  // console.log(children);
                  item.children = children;
                }
              });
            });
            this.tabledata = newtabledata;
          });
          // console.log("套餐详情-额外收费项", this.ExtraChargeList);
          let extra = res.data.extra;
          let id = [];
          let NewExtraCharge = [];
          extra.forEach((el, index) => {
            id.push(el.pdPId);
            NewExtraCharge.push({
              qdBcId: this.form.qbcId,
              qdType: "2",
              qdPId: el.pdPId,
              // qdQId: "",
            });
          });
          // this.NewExtraCharge = id;
          this.NewExtraChargeValue = id;
          this.NewExtraCharge = NewExtraCharge;
        }

        // this.oldTableData = this.tabledata; //记录原始表格数据，计算增加项和减少项
      });
    },
    // 点击编辑，隐藏详情  显示编辑页面
    showEdit() {
      this.$route.meta.title = "报价编辑";
      this.viewShow = false;
    },
    afterVisibleChange(val) {},
    // 展开抽屉
    showDrawer(name, title, type, record) {
      // console.log("showDrawer_record", record, this.form);
      this.visibleDrawer = true;
      this.DrawerTitle = title;
      this.QuotationEditShow = false;
      this.QuotationViewShow = false;
      this[name] = true;
      if (record) {
        this.record = record;
      }
      this.recordType = type;
      this.record.qdBcId = this.form.qbcId;
      this.record.qdQId = this.form.qid;
      this.record.qarea = this.form.qarea; //施工量（面积）
      this.record.qdPtId = this.form.qptId; //套餐id
    },
    // 查询额外收费项列表
    GetExtraChargeList(ecBcIds) {
      let queryParam = {
        ecBcIds: ecBcIds ? ecBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["ecBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetExtraChargeList(params).then((res) => {
        this.ExtraChargeList = res.data;
        // console.log("查询额外收费项列表", res.data);
        res.data.forEach((el) => {
          this.ExtraChargeListId[el.ecId] = el.ecName;
        });
      });
    },
    // 根据额外收费项的id获取name
    ExtraChargeListResult(e) {
      return this.ExtraChargeListId[e];
    },
    // 额外收费项的选择
    changeradioBtn(e) {
      // pdBcId 分公司id  pdPId 额外收费项id
      //pdType 类型2  pdPrice价格   pdPtId模版id
      // console.log("额外收费项的选择", e);
      this.NewExtraCharge = [];
      e.forEach((el, index) => {
        this.NewExtraCharge.push({
          qdBcId: this.form.qbcId,
          qdType: "2",
          qdPId: el,
          qdQId: this.form.qid,
          qdOrder: index,
        });
      });
      // console.log("this.NewExtraCharge", this.NewExtraCharge);
    },
    onClose() {
      this.visibleDrawer = false;
      this.QuotationEditShow = false;
      this.record = {};
    },
    // 表格编辑
    tabledataChange(event) {
      // console.log(
      //   "this.$refs.QuotationEdit.emitForm",
      //   this.$refs.QuotationEdit.emitForm
      // );
      let emitForm = JSON.parse(this.$refs.QuotationEdit.emitForm);
      console.log("tabledataChange", emitForm);
      if (this.tabledata.length == 0) {
        this.onClose();
        this.tabledata.push(emitForm);
      } else {
        // 下标大于-1   证明存在
        let findIndex = this.tabledata.findIndex((item) => {
          return item.wpId == emitForm.wpId;
        });
        if (findIndex > -1) {
          this.tabledata.forEach((el, index) => {
            if (el.wpId == emitForm.wpId) {
              // el.children.push(emitForm.children[0]);
              // this.onClose();
              let pdpIdindex = el.children.findIndex((item) => {
                return item.qdPId == emitForm.children[0].qdPId;
              });
              console.log("pdpIdindex", pdpIdindex);
              // 如果是编辑，直接赋值
              if (this.recordType == "edit") {
                // el.children = emitForm.children[0];
                // console.log("el.children", el.children);
                el.children.forEach((elChildren, index) => {
                  // console.log("elChildren", elChildren, index);
                  if (elChildren.qdPId == emitForm.children[0].qdPId) {
                    el.children[index] = emitForm.children[0];
                  } else {
                  }
                  // this.$set(this.tabledata,)
                });
                this.onClose();
              } else {
                // 如果是添加，判断是否重复添加
                if (pdpIdindex == -1) {
                  el.children.push(emitForm.children[0]);
                  this.onClose();
                } else {
                  this.$message.error("施工项重复设置");
                }
              }
            } else {
              // this.$message.error("施工项重复设置");
            }
          });
        } else {
          this.tabledata.push(emitForm);
          this.onClose();
        }
      }

      if (this.recordType == "edit") {
        // console.log("编辑之后的表格数据", this.tabledata);
        let qdMoney = 0; //施工项总金额
        let maAdjPrice = 0; //调整项总金额
        let qmmoney = 0; //主材金额
        this.tabledata.forEach((el) => {
          el.children.forEach((item) => {
            // qdMoney = qdMoney * 1 + item.qdMoney * 1;
            let money = item.qdQuantity * item.qdPrice;
            qdMoney = qdMoney * 1 + money;
            // console.log("循环相加主材金额", item.qdMPrice);
            qmmoney = qmmoney * 1 + item.qdMPrice * 1;
            if (item.tmaterialAdjustments) {
              item.tmaterialAdjustments.forEach((Adjus) => {
                maAdjPrice = maAdjPrice * 1 + Adjus.maAdjPrice * 1;
              });
              // console.log("调整费用", AdjPrice, item.tmaterialAdjustments);
            }
          });
        });
        this.tablePrice = qdMoney;
        this.form.qtotal = this.tablePrice * 1 + this.ExtraChargePrice * 1; //总金额
        this.form.qchange = maAdjPrice; //非标项调整费用
        this.form.qmmoney = qmmoney.toFixed(2); //主材金额
        // console.log("主材金额之和", this.form.qmmoney);
      }
      // && this.recordType == "add"
      // 只要新增或编辑时会触发此函数
      // 如果是新增的施工项，判断原数据里有没有此施工项，如果有且大于计算占比得来的施工量，则阶梯计费
      // 如果没有，则需要重新计算增加项
      let qincrease = 0; //增加项
      if (this.$route.query.type == "edit" || this.$route.query.type == "add") {
        // 判断修改的是否是原始表格数据里含有的施工项，如果不是，那直接计算在增加项里
        let isTrue = false;
        for (let el in this.oldTableData) {
          if (this.oldTableData[el].wpId == emitForm.wpId) {
            for (let item in this.oldTableData[el].children) {
              // 判断 原始数据里是否含有新编辑的数据
              if (
                this.oldTableData[el].children[item].pdPId ==
                emitForm.children[0].qdPId
              ) {
                // 如果增加了一条原始数据中含的施工项，
                if (this.recordType == "add") {
                  //  如果新加的是原始数据里含有的施工项，则把减少项给加回来
                  // 需要先判断 根据比占比计算出来的施工量 和 客户定义的施工量 的大小
                  // 根据占比计算施工量
                  console.log("emitForm.children[0]", emitForm.children[0]);
                  let oldQuantity =
                    this.form.qarea * emitForm.children[0].qdRatio;
                  let CheapPrice = emitForm.children[0].qdCheapPrice; //优惠单价
                  let price = emitForm.children[0].qdPrice; //单价
                  let changePrice = price - CheapPrice;
                  // 减少项=根据占比计算施工量*（单价-优惠价）
                  let qreduce = oldQuantity * changePrice;
                  this.form.qreduce = this.form.qreduce * 1 - qreduce;
                  // this.form.qreduce
                  console.log(
                    "添加一条原有的施工项",
                    this.form.qarea,
                    emitForm.children[0].qdRatio,
                    CheapPrice,
                    price
                  );
                }
                isTrue = true;
                break;
              } else {
                isTrue = false;
              }
            }
          }
        }
        // console.log("isTrue", isTrue);
        // false  代表原始数据里不含着条数据
        let addTableData = [...this.addTableData];
        let index = -1;
        if (isTrue == false) {
          if (addTableData.length > 0) {
            aaa: for (let el in addTableData) {
              // console.log("2222222");
              if (addTableData[el].wpId == emitForm.wpId) {
                for (let item in addTableData[el].children) {
                  //   // 如果新增数组里有这条数据，则直接赋值
                  if (
                    addTableData[el].children[item].qdPId ==
                    emitForm.children[0].qdPId
                  ) {
                    addTableData[el].children[item] = emitForm.children[0];
                    index = item;
                    break aaa;
                  } else {
                    // if (item >= addTableData[el].children.length - 1) {
                    //   // 如果没，则添加
                    //   addTableData[el].children.push(emitForm.children[0]);
                    // }
                    if (index == -1) {
                      // 如果没，则添加
                      addTableData[el].children.push(emitForm.children[0]);
                    }
                  }
                }
              } else {
                // console.log("1111111");
              }
            }
          } else {
            addTableData.push(emitForm);
          }
        }
        this.addTableData = addTableData;
        // console.log("this.addTableData", this.addTableData);
      }
      // 计算所有增加项的施工量*施工单位单价
      this.addTableData.forEach((el) => {
        el.children.forEach((item) => {
          // qincrease = qincrease * 1 + item.qdMoney;
          qincrease = qincrease * 1 + item.qdPrice * item.qdQuantity;
        });
      });
      // this.form.qincrease = this.form.qincrease * 1 + qincrease * 1;
      // 表格里的增加项
      this.qincrease_table = qincrease * 1;
      // 计算优惠金额
      let qcheap = 0;
      this.tabledata.forEach((el) => {
        el.children.forEach((item) => {
          if (item.qdCheapMoney) {
            qcheap = qcheap * 1 + item.qdCheapMoney * 1;
          }
        });
      });
      this.form.qcheap = qcheap.toFixed(2);
    },
    // 提交按钮
    submitClick() {
      // console.log("form", this.form);
      if (this.$route.query.type == "edit") {
        this.UpdateQuotation();
        // this.UpdateQuotationDetailList();
      } else {
        // 新建基本信息
        this.AddQuotation();
      }
    },
    // 新建基本信息
    AddQuotation() {
      let params = this.form;
      console.log("this.form", this.form);
      params.project = this.tabledata;
      params.project.forEach((el) => {
        el.children.forEach((item, index) => {
          item.qdWpId = el.wpId;
          item.qdQid = this.form.qid;
          item.qdBcId = this.form.qbcId;
          item.qdType = "1"; //1施工项  2额外施工项
          item.qdOrder = index;
          item.mainMaterials = item.mainMaterials
            ? JSON.parse(item.mainMaterials)
            : [];
        });
      });
      // 额外收费项
      params.extra = this.NewExtraCharge;
      AddQuotation(params).then((res) => {
        // console.log(res.data);
        // this.AddQuotationDetailList(res.data);
        if (res.code == 200) {
          this.$message.success("新建报价成功");
          this.$router.push({
            path: "/QuotationList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改基本信息
    UpdateQuotation() {
      let params = this.form;
      params.project = this.tabledata;
      params.project.forEach((el) => {
        el.children.forEach((item, index) => {
          item.qdWpId = el.wpId;
          item.qdQid = this.form.qid;
          item.qdBcId = this.form.qbcId;
          item.qdType = "1"; //1施工项  2额外施工项
          item.qdOrder = index;
          // item.qreduce = 3305.1;
          item.mainMaterials = JSON.parse(item.mainMaterials);
        });
      });
      // 额外收费项
      params.extra = this.NewExtraCharge;
      // console.log(params, this.tabledata);
      UpdateQuotation(params).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("修改报价成功");
          this.$router.push({
            path: "/QuotationList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onDelete(record) {
      // console.log("delete", record, this.oldTableData);
      const tabledata = [...this.tabledata];
      // this.tabledata = tabledata.filter(item => item.key !== record.pdPId);
      tabledata.forEach((item) => {
        // console.log("item", item);
        let newchildren = [];
        if (item.wpId == record.childrenWpId) {
          item.children.forEach((el) => {
            if (el.qdPId != record.qdPId) {
              newchildren.push(el);
              // console.log(el);
            }
          });
          item.children = newchildren;
        }
      });
      this.tabledata = tabledata;
      // 根据被删除施工工序和施工项 判断是否是表格里的原始数据  计算减少项
      let qreduce = 0; //减少项
      this.oldTableData.forEach((el) => {
        if (el.wpId == record.childrenWpId) {
          if (el.children.length > 0) {
            el.children.forEach((item) => {
              if (item.pdPId == record.qdPId) {
                // qreduce = qreduce * 1 + record.qdMoney;
                // qreduce = qreduce * 1 + record.qdPrice * record.qdQuantity;
                // 减少项=原来的施工量*（单价-优惠单价）
                let price = record.qdPrice - item.pdCheapPrice;
                let Quantity = this.form.qarea * record.qdRatio;
                qreduce = qreduce * 1 + price * Quantity;
              }
            });
          }
        }
      });
      this.form.qreduce = qreduce.toFixed(2) * 1;
      // 过滤被删除的增加项
      let addTableData = this.addTableData;
      addTableData.forEach((el) => {
        // el.children.filter((item) => {
        //   // console.log("00000", item.qdPId, record.qdPId);
        //   return item.qdPId != record.qdPId;
        // });
        // return el.children;
        if (el.wpId == record.childrenWpId) {
          let newchildren = [];
          el.children.forEach((item) => {
            if (item.qdPId != record.qdPId) {
              newchildren.push(item);
            }
          });
          el.children = newchildren;
        }
      });

      this.addTableData = addTableData;
      // console.log("过滤被删除的增加项", record.qdPId, this.addTableData);
      // 计算所有增加项的施工量*施工单位单价
      let qincrease = 0;
      this.addTableData.forEach((el) => {
        el.children.forEach((item) => {
          // console.log("计算增加项delete", item.qdPrice, item.qdQuantity);
          // qincrease = qincrease * 1 + item.qdMoney;
          // qincrease = qincrease * 1 + record.qdPrice * qdQuantity;
          qincrease = qincrease * 1 + item.qdPrice * item.qdQuantity;
        });
      });
      // this.form.qincrease = qincrease + this.ExtraChargePrice * 1;
      this.qincrease_table = qincrease;
    },
    // 跳转到报价列表页面
    cancellClick() {
      this.$router.push({
        path: "/QuotationList",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.infoTit {
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.basicInfo {
  text-align: left;

  .displayFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .margin_top {
    margin-top: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.margin_right {
  margin-right: 10px;
}
.btnsDisplayflex {
  margin-bottom: 20px;
  display: flex;
  div {
    margin-right: 20px;
    font-weight: 800;
  }
  /deep/ .ant-btn {
    margin-right: 20px;
  }
  .btnsDisplayflex_inner {
    display: flex;
  }
  /deep/.ant-col-3 {
    width: 150px;
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
.editBtn {
  margin-right: 20px;
}
.delName {
  color: red;
}
.addBtn {
  text-align: left;
  margin-top: 20px;
}
.bottom {
  //   margin: 10px 50px;
  .displayFlex {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .ant-table-tbody > tr {
  height: 90px;
}
/deep/.ant-checkbox-group {
  width: 80%;
  text-align: left;
}
.basicInfo_view {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 300px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .basicInfo_view_hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// /deep/.ant-input-disabled
/deep/.ant-input[disabled],
.ant-input-disabled,
.ant-select-disabled {
  color: rgba(0, 0, 0, 1);
}
.padding_bottom {
  padding-bottom: 100px;
}

.basicInfo_edit {
  /deep/.ant-row {
    width: 400px;
    margin: 10px 50px 10px 0;
  }
  .colorChange {
    color: #ff834c;
    font-weight: 800;
  }
  .width240 {
    width: 240px;
  }
  /deep/ .width80 {
    width: 80px;
    margin-right: 10px;
  }
  /deep/ .width140 {
    width: 140px;
    margin-right: 10px;
  }
  .width50 {
    width: 50px;
  }
  .textareaWidth {
    // /deep/ .ant-form-item > .ant-form-item-label {
    //   // width: 80px;
    // }
    /deep/.ant-form-item {
      width: 98%;
    }
    /deep/.ant-form-item-control-wrapper {
      width: 90%;
    }
  }
}
/deep/ .a-tooltip {
  width: 800px !important;
}
/deep/ .ant-tooltip-inner {
  width: 800px !important;
  background-color: red;
}
/deep/.ant-tooltip-content {
  width: 800px !important;
  background-color: red;
}
</style>
