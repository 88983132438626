<template>
  <div class="QuotationView">
    <div class="title">基本信息</div>
    <div class="displayFlex">
      <div>
        <div class="displayFlex">
          <div class="leftLabel">所属公司：</div>
          {{ CompanyIdResult(form.qdBcId) }}
        </div>
        <div class="displayFlex">
          <div class="leftLabel">所属施工工序:</div>
          {{ WorkProceResult(form.qdWpId) }}
        </div>
        <div class="displayFlex">
          <div class="leftLabel">施工项名称:</div>
          {{
            ProjectListResult(form.qdPId)
              ? ProjectListResult(form.qdPId)
              : form.qdPId
          }}
        </div>
      </div>
      <div class="marginLeft50">
        <div class="displayFlex">
          <div class="leftLabel">施工量:</div>
          {{ form.qdQuantity }}
        </div>
        <div class="displayFlex">
          <div class="leftLabel">工程量计量单位:</div>
          {{ form.qdUnit }}
        </div>
        <div class="displayFlex">
          <div class="leftLabel">核算单位单价(元):</div>
          {{ form.qdPrice }}
        </div>
      </div>
    </div>
    <div v-if="tableMainShow">
      <div class="title">主材清单：</div>
      <div class="table tableWidth">
        <a-table
          :columns="columnsMain"
          :data-source="dataMain"
          bordered
          key="maMId"
          :pagination="false"
        >
          <span slot="maMId" slot-scope="text">
            <div>{{ ProjectMaterialIdListresult(text) }}</div>
          </span>
          <span slot="maProvider" slot-scope="text">
            {{ MaterialSupplier[text] }}
          </span>
        </a-table>
      </div>
    </div>
    <div v-if="tableAuxShow">
      <div class="title">辅材清单：</div>
      <div class="table tableWidth">
        <a-table
          :columns="columnsMain"
          :data-source="dataAux"
          bordered
          key="maMId"
          :pagination="false"
        >
          <span slot="maMId" slot-scope="text">
            <div>{{ ProjectMaterialIdListresult(text) }}</div>
          </span>
          <span slot="maProvider" slot-scope="text">
            {{ MaterialSupplier[text] }}
          </span>
        </a-table>
      </div>
    </div>
    <div class="title">非标项调整(选填)</div>
    <div v-if="tableAuxShow">
      <div class="table tableAdjust">
        <a-table
          :columns="columnsAdjust"
          :data-source="dataAdjust"
          bordered
          key="maMId"
          :pagination="false"
        >
          <span slot="maMId" slot-scope="text">
            <div>{{ ProjectMaterialIdListresult(text) }}</div>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import { GetCompanies } from "@/api/device";
import {
  GetWorkProceList,
  GetProjectList,
  GetProjectMaterialList,
} from "@/api/apiJF/template";
import { GetPackageDetail } from "@/api/apiJF/package";

const columnsMain = [
  {
    title: "材料",
    dataIndex: "maMId",
    // width: "90px",
    scopedSlots: { customRender: "maMId" },
  },
  {
    title: "用量",
    dataIndex: "maQuantity",
    scopedSlots: { customRender: "maQuantity" },
    width: "90px",
  },
  {
    title: "提供方",
    dataIndex: "maProvider",
    scopedSlots: { customRender: "maProvider" },
    width: "90px",
  },
];
const columnsAdjust = [
  {
    title: "调整材料",
    dataIndex: "maMId",
    scopedSlots: { customRender: "maMId" },
    width: "100px",
  },
  {
    title: "调整施工量",
    dataIndex: "maAdjQuantity",
    scopedSlots: { customRender: "maAdjQuantity" },
    width: "90px",
  },
  {
    title: "调整因子",
    dataIndex: "maAdjFactor",
    width: "70px",
    scopedSlots: { customRender: "maAdjFactor" },
  },
  {
    title: "材料用量",
    dataIndex: "maQuantity",
    width: "70px",
    scopedSlots: { customRender: "maQuantity" },
  },
  {
    title: "用量单位",
    dataIndex: "maAdjUnit",
    scopedSlots: { customRender: "maAdjUnit" },
    width: "70px",
  },
  {
    title: "调整费用(元)",
    dataIndex: "maAdjPrice",
    scopedSlots: { customRender: "maAdjPrice" },
    width: "90px",
  },
  {
    title: "备注",
    dataIndex: "maDesc",
    scopedSlots: { customRender: "maDesc" },
    width: "80px",
  },
];
const dataMain = []; //主材
const dataAux = []; //辅材
const dataAdjust = []; //调整因子

export default {
  props: {
    record: {
      type: String,
      default: {},
    },
    type: {
      type: String,
    },
  },
  data() {
    this.cacheDataMain = dataMain.map((item) => ({ ...item }));
    this.cacheDataAux = dataAux.map((item) => ({ ...item }));
    this.cacheDataAdjust = dataAdjust.map((item) => ({ ...item }));
    return {
      form: {
        // qdBcId: "",
        // qdWpId: "",
        // pdPId: "",
        // pdRatio: "",
        // pdDesc: "",
        qdId: "", //报价详情ID
        qdBcId: "", //分公司id
        qdQId: "", //报价id
        qdWpId: "", //施工工序id
        qdOrder: "", //序号
        qdPId: "", //施工项id
        qdPName: "", //项目名称
        qdUnit: "", //单位
        qdQuantity: 0, //数量
        qdPrice: "", //单价
        qdMoney: "", //金额
        qdDesc: "", //说明
        qdSupId: "", //父级id
        qdType: "", //施工类型
        qdCreateTime: "",
        qdDelFlag: "",
        // 平方米 米  间
        qdCheapMoney: 0, //优惠金额
        qdCheapPrice: 0, //优惠单价
        qdRatio: 0, //施工量占比
      },
      qdUnitSelectValue: [
        { value: "平方米", label: "平方米" },
        { value: "米", label: "米" },
        { value: "间", label: "间" },
      ],
      horizontal: "horizontal",
      GetCompanyList: {},
      CompanyListid: [],
      // 查询参数
      queryParam: {
        qBcIds: "24",
      },

      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      emitForm: {},
      columnsMain,
      columnsAdjust,
      dataMain,
      dataAux,
      dataAdjust,
      ProjectMaterialListMain: [], //主材--物料信息数据
      ProjectMaterialListAux: [], //辅材--物料信息数据
      MaterialSupplier: ["公司", "业主"], //材料提供者
      oldTableData: [], //套餐详情的原始数据
      CompanyIdList: [],
      WorkProceIdList: [],
      ProjectIdList: [],
      ProjectMaterialIdList: [],
      tableMainShow: false,
      tableAuxShow: false,
    };
  },
  computed: {},
  created() {
    this.dataMain = [];
    this.dataAux = [];
    this.dataAdjust = [];
    this.cacheDataMain = [];
    this.cacheDataAux = [];
    this.cacheDataAdjust = [];
    this.GetCompaniesId();
    // this.form.qBcId = this.record.qBcId;

    let record = JSON.parse(this.record);
    console.log("record", record);

    if (this.type == "view") {
      for (let el in record) {
        this.form[el] = record[el];
      }
      // 主材
      this.dataMain = record.mainMaterials
        ? JSON.parse(record.mainMaterials)
        : [];
      this.cacheDataMain =
        this.dataMain.length > 0
          ? this.dataMain.map((item) => ({ ...item }))
          : [];
      // 辅材
      this.dataAux = record.subMaterials ? record.subMaterials : [];
      this.cacheDataAux = this.dataAux.map((item) => ({ ...item }));
      console.log(" this.dataMain", this.dataMain);
      console.log(" this.dataAux ", this.dataAux);
      // 调整因子
      this.dataAdjust = record.tmaterialAdjustments
        ? record.tmaterialAdjustments
        : [];
      this.cacheDataAdjust = this.dataAdjust.map((item) => ({ ...item }));
    }
    this.form.qdBcId = record.qdBcId;
    // 优惠单价

    if (record.qdWpId) {
      this.form.qdWpId = record.qdWpId;
      // for (let el in record) {
      //   this.form[el] = record[el];
      // }
      // console.log(" this.form", this.form);
    } else {
      this.form.qdWpId = record.wpId ? record.wpId : record.childrenWpId;
    }
    // 获取套餐详情
    this.GetPackageDetail(JSON.parse(this.record).qdPtId, this.form.qdBcId);
  },
  watch: {
    "form.qdBcId": {
      handler(newValue) {
        // console.log("form.qdBcId", newValue);
        // // 获取施工工序
        this.GetWorkProceList(newValue);
        // 获取施工项
        this.GetProjectList(newValue);
        // 获取物料信息--主材
        // this.GetProjectMaterialList(newValue);
        // this.GetProjectMaterialList_Aux(newValue);
      },
    },
    "form.qdPId": {
      handler(newValue) {
        // console.log("form.qdPId", newValue);
        // 获取物料信息--主材
        this.GetProjectMaterialList(this.form.qdBcId, newValue);
        // 获取物料信息--辅材
        this.GetProjectMaterialList_Aux(this.form.qdBcId, newValue);
        let index = -1;
        this.oldTableData.forEach((el) => {
          if (el.wpId == this.form.qdWpId) {
            el.children.forEach((item, i) => {
              if (item.pdPId == newValue) {
                // console.log(" el.children", el.children.length, i);

                // this.form.qdUnit = item.;
                this.form.qdPrice = item.pdPrice;
                index = i;
              } else {
              }
            });
          }
        });
        // console.log("index", index);
        setTimeout(() => {
          // if (index == -1) {
          //   // 改变施工项，获取施工项的单位和单价进行赋值
          //   this.ProjectList.forEach((el) => {
          //     if (el.pid == newValue) {
          //       this.form.qdUnit = el.punit;
          //       this.form.qdPrice = el.psalesPrice;
          //       console.log("el.pdCheapPrice", el);
          //     }
          //   });
          // }

          // // 改变施工项，获取施工项的单位和单价进行赋值
          this.ProjectList.forEach((el) => {
            if (el.pid == newValue) {
              this.form.qdUnit = el.punit;
              if (index == -1) {
                this.form.qdPrice = el.psalesPrice;
              }
              // this.form.qdPrice = el.psalesPrice;
              // console.log("el.pdCheapPrice", el);
            }
          });
        });
      },
      // immediate: true,
    },
    // dataAdjust: {
    //   handler(newValue) {
    //     //   console.log("dataAdjust", newValue);
    //     if (newValue != "") {
    //       newValue.forEach((el, index) => {
    //         let value = this.ProjectMaterialListAux.filter((item) => {
    //           return item.pmMId == el.maMId;
    //         });

    //         if (value.length > 0) {
    //           // console.log("value", value);
    //           el.maAdjPrice = el.maQuantity * value[0].tmaterial.mSalesPrice;
    //           el.maQuantity = el.maAdjQuantity * el.maAdjFactor;
    //         }
    //       });
    //       // console.log("dataAdjust", newValue);
    //     }
    //   },
    //   deep: true,
    // },
    // "form.qdQuantity": {
    //   handler(value) {
    //     let record = JSON.parse(this.record);
    //     console.log("form.qdQuantity111", this.form.qdRatio);
    //     if (this.form.qdRatio) {
    //       // 根据施工占比计算得出的施工量
    //       let oldQuantity = record.qarea * 1 * this.form.qdRatio * 1;
    //       // 修改的施工项的施工量
    //       let changeQuantity = value * 1;
    //       console.log("oldQuantity", oldQuantity, changeQuantity);
    //       // qdCheapMoney   优惠金额
    //       if (oldQuantity >= changeQuantity) {
    //         this.form.qdCheapMoney = changeQuantity * this.form.qdCheapPrice;
    //       } else {
    //         let oldqdCheapMoney = oldQuantity * this.form.qdCheapPrice;
    //         let addChange = changeQuantity - oldQuantity;
    //         this.form.qdCheapMoney =
    //           addChange * this.form.qdPrice * 1 + oldqdCheapMoney * 1;
    //       }
    //     } else {
    //       this.form.qdCheapMoney = 0;
    //     }
    //     // console.log("优惠总金额111", this.form.qdCheapMoney);
    //     // this.form.qdQuantity = value ? record.qarea * 1 * value : 0; //计算施工量
    //   },
    //   deep: true,
    //   // immediate: true,
    // },
    // 施工量占比
    // "form.qdRatio": {
    //   handler(newValue) {
    //     console.log("form.qdRatio", newValue);
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            // this.CompanyList[el.bc_id] = el.bc_name;
            this.CompanyIdList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.qBcIds = this.CompanyListid.toString();
          // 获取物料信息
          // this.GetProjectMaterialList();
          // // 获取施工工序
          // this.GetWorkProceList();
          // 获取施工项
          // this.GetProjectList();
          // // 获取套餐类别
          // this.GetPackageTypeList();
        });
    },
    // 根据分公司id获取分公司名称
    CompanyIdResult(a) {
      return this.CompanyIdList[a];
    },
    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params).then((res) => {
        // console.log("施工工序", res.data);
        this.WorkProceList = res.data;
        res.data.forEach((el) => {
          this.WorkProceIdList[el.wpId] = el.wpName;
        });
        // console.log("this.WorkProceList", this.WorkProceList);
      });
    },
    // 根据施工工序id获取name
    WorkProceResult(a) {
      return this.WorkProceIdList[a];
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        // console.log("施工项", res.data);
        this.ProjectList = res.data;
        res.data.forEach((el) => {
          this.ProjectIdList[el.pid] = el.pname;
          el.children.forEach((item) => {
            this.ProjectIdList[item.pid] = item.pname;
          });
        });

        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 根据施工项id查询施工工序名称
    ProjectListResult(a) {
      return this.ProjectIdList[a];
    },
    // 获取物料信息---主材
    GetProjectMaterialList(pmBcIds, pmPId) {
      let queryParam = {
        pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "1",
      };
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("物料信息", res.data);
        this.ProjectMaterialListMain = res.data;
        res.data.forEach((el) => {
          this.ProjectMaterialIdList[el.pmMId] = el.tmaterial.mName;
        });
        this.tableMainShow = true;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    // 获取物料信息---辅材
    GetProjectMaterialList_Aux(pmBcIds, pmPId) {
      let queryParam = {
        pmPId: pmPId ? pmPId : JSON.parse(this.record).qdPId,
        pmBcIds: pmBcIds ? pmBcIds : this.CompanyListid.toString(),
        pmType: "2",
      };
      let orderParam = ["pmPId", "pmBcIds", "pmType"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectMaterialList(params).then((res) => {
        // console.log("辅材物料信息", res.data);
        this.ProjectMaterialListAux = res.data;
        res.data.forEach((el) => {
          this.ProjectMaterialIdList[el.pmMId] = el.tmaterial.mName;
        });
        this.tableAuxShow = true;
        // console.log("this.ProjectList", this.ProjectList);
      });
    },
    //根据物料id获取物料名称
    ProjectMaterialIdListresult(a) {
      return this.ProjectMaterialIdList[a];
    },
    // 获取套餐详情
    GetPackageDetail(pdPtId, pdBcId) {
      let queryParam = {
        pdPtId: pdPtId,
        pdBcId: pdBcId,
      };
      let orderParam = ["pdPtId", "pdBcId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPackageDetail(params).then((res) => {
        // console.log("套餐详情", res.data);
        let project = res.data.project;
        // 套餐详情里的原始数据
        this.oldTableData = res.data.project;
        // let newtabledata = [];
        // 根据施工项id获取此施工项的施工占比
        project.forEach((el) => {
          // console.log("111111111", el);
          if (el.wpId == this.form.qdWpId) {
            if (el.children) {
              el.children.forEach((item) => {
                // console.log("22222", item.pdPId, this.form.qdPId);
                if (item.pdPId == this.form.qdPId) {
                  console.log("item", item);
                  // 施工项占比
                  this.form.qdRatio = item.pdRatio;
                  // 优惠单价
                  this.form.qdCheapPrice = item.pdCheapPrice;
                  // 单价
                  this.form.qdPrice = item.pdPrice;
                  // // this.form.
                  // // 根据套餐施工占比算出施工量
                  let record = JSON.parse(this.record);
                  // 根据施工占比计算得出的施工量
                  let oldQuantity = record.qarea * 1 * item.pdRatio * 1;
                  // 修改的施工项的施工量
                  let changeQuantity = this.form.qdQuantity * 1;
                  // console.log("oldQuantity", oldQuantity, changeQuantity);
                  // qdCheapMoney   优惠金额
                  if (oldQuantity >= changeQuantity) {
                    this.form.qdCheapMoney =
                      changeQuantity * this.form.qdCheapPrice;
                  } else {
                    let oldqdCheapMoney = oldQuantity * this.form.qdCheapPrice;
                    let addChange = changeQuantity - oldQuantity;
                    this.form.qdCheapMoney =
                      addChange * this.form.qdPrice * 1 + oldqdCheapMoney * 1;
                  }
                  console.log("优惠总金额", this.form.qdCheapMoney);
                  // console.log(" item.pdRatio", item);
                  // console.log("this.type", this.type);
                  // 如果施工量为0，则根据总施工量面积和施工占比计算施工量，
                  // 如果施工量不为0，则证明不是新增的，是人为修改之后的施工量，则不跟随施工总面积改变
                  if (this.type == "add") {
                    let record = JSON.parse(this.record);
                    // console.log("form.qdRatio", record.qarea, item.pdRatio);
                    this.form.qdQuantity = record.qarea * 1 * item.pdRatio; //计算施工量
                    // 查询施工单位
                    this.form.qdUnit = item.tproject ? item.tproject.punit : "";
                  }
                } else {
                  // 施工项占比
                  // this.form.qdRatio = 0;
                }
              });
            }
          } else {
            // // 优惠单价
            // this.form.qdCheapPrice = 0;
            // // 优惠金额
            // this.form.qdCheapPrice = 0;
          }
        });
      });
    },

    onClose() {
      this.$emit("onClose");
      this.emitForm = {};
    },
    // 主材
    addItemMain(key) {
      // console.log("addItemMain", key);
      this.dataMain.push({
        maMId: "", //材料id
        maQuantity: "", //用量
        maProvider: 1, //提供方   1公司   2业主
        maType: "1", //主材
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: JSON.parse(this.record).childrenWpId,
        maPId: JSON.parse(this.record).qdPId, //施工项id
        maQid: JSON.parse(this.record).qdQId, //报价id
      });
      this.cacheDataMain = this.dataMain.map((item) => ({ ...item }));
    },

    // 辅材
    addItemAux(key) {
      // console.log("addItemAux", key);
      this.dataAux.push({
        maMId: "", //材料id
        maQuantity: "", //用量
        maProvider: 1, //提供方   1公司   2业主
        maType: "2", //辅材
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: JSON.parse(this.record).childrenWpId,
        maPId: JSON.parse(this.record).qdPId, //施工项id
        maQid: JSON.parse(this.record).qdQId, //报价id
      });
      this.cachedataAux = this.dataAux.map((item) => ({ ...item }));
    },

    // 非标项调整
    addItemAdjust(key) {
      // console.log("addItemAdjust", key);
      this.dataAdjust.push({
        maMId: "", //材料id
        maAdjQuantity: "", //调整施工量
        maAdjFactor: "", //调整因子
        maQuantity: "", //材料用量
        maAdjUnit: "", //用量单位
        maAdjPrice: "", //调整费用
        maDesc: "", //备注
        // maProvider: 1, //提供方   1公司   2业主
        // maType: "2", //辅材
        maBcId: JSON.parse(this.record).qdBcId, //分公司id
        maWpId: JSON.parse(this.record).childrenWpId,
        maPId: JSON.parse(this.record).qdPId, //施工项id
        maQid: JSON.parse(this.record).qdQId, //报价id
      });
      this.cacheDataAdjust = this.dataAdjust.map((item) => ({ ...item }));
    },
  },
};
</script>
<style lang="less" scoped>
.QuotationView {
  padding: 0 20px;
  background-color: #fff;
}
/deep/.ant-row {
  display: flex;
  // width: 90%;
  width: 100%;
}
/deep/.anticon {
  margin-right: 5px;
}
/deep/ .ant-form-item-children {
  display: flex;
  // text-align: center;
  // justify-content: center;
  align-items: center;
  // justify-content: right;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.title {
  font-weight: 800;
  margin: 20px 0;
  font-size: 18px;
}
.table {
  text-align: center;
  margin-top: 20px;
  //   width: 100%;
  margin-left: 10%;
}
/deep/.table .ant-form-item-control-wrapper {
  width: 100%;
  margin-left: 50px;
}
.editable-cell {
  position: relative;
}

.displayFlex {
  display: flex;
  font-size: 14px;
  margin: 10px;
  font-weight: 800;

  .leftLabel {
    width: 150px;
    font-weight: 400;
  }
  .marginLeft50 {
    margin-left: 50px;
  }
}

.tableWidth {
  width: 80%;
}
/deep/.ant-table-thead > tr > th {
  background-color: #eaf2ff;
}
</style>